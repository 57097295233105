import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import { Box, Container, Grid, Themed } from "theme-ui";
import { Link } from "gatsby";
import ContainerNarrow from "../components/container-narrow";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <Grid gap={[5, 5, 5, 7, 7, 8]} sx={{
        gridTemplateColumns: `repeat(auto-fit, minmax(17.08rem, 1fr))`,
        py: 6
      }} mdxType="Grid">
        <Box sx={{
          ul: {
            padding: 0,
            margin: 0,
            listStyle: `none`
          },
          li: {
            marginBottom: [4, 4, 4, 3, 3, 3]
          }
        }} mdxType="Box">
          <h1>{`Maternidades cuestionadas y retirada de custodias: violencia institucional, racismo y falso SAP`}</h1>
          <p>{`Esta mesa nos muestra una realidad que nos golpea la cara: la de los “arrancamientos” o quitas de custodias a madres en el estado español. Esta problemática sigue siendo invisible y es necesario que se priorice en las agendas feministas.`}</p>
          <p><strong parentName="p">{`Participantes`}</strong></p>
          <ul>
            <li parentName="ul">
              <Themed.a as={Link} to={`/soledad-gonzalez`}>Soledad González</Themed.a>.<br /><small>Feminista, politóloga, integrante de Cotidiano Mujer (Uruguay)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/rebeca-gregson-tovar`}>Rebeca Gregson Tovar</Themed.a>.<br /><small>Madre migrada, psicóloga social especialista en participación y acción comunitaria. La Tregua e IPECAL (Barcelona)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/silvia-aquiles`}>Silvia Aquiles</Themed.a>.<br /><small>Activista anti-SAP (Barcelona)</small>
            </li>
            <li parentName="ul">
              <Themed.a as={Link} to={`/daniela-ortiz`}>Daniela Ortiz</Themed.a>.<br /><small>Artista, activistas y migrante anticolonial (Perú)</small>
            </li>
          </ul>
          <p><strong parentName="p">{`Moderación`}</strong></p>
          <p>{`Sílvia Aldavert Garcia.`}<br /><small>{`Coordinadora de L’Associació de Drets Sexuals i Reproductius e integrante de la Campaña por el Derecho al Aborto.`}</small></p>
        </Box>
        <Box sx={{
          ".mdx-embed": {
            backgroundColor: `primary`,
            padding: 4
          }
        }} mdxType="Box">
          <YouTube youTubeId="gccjtvdS_t8" mdxType="YouTube" />
          <Themed.p><small>Resumen</small></Themed.p>
        </Box>
      </Grid>
    </Container>
    <ContainerNarrow mdxType="ContainerNarrow">
      <p>{`Con `}<strong parentName="p">{`Soledad González`}</strong>{` conocemos la realidad de los “arrancamientos” o quitas de custodias a madres, entendiendo que estos son una forma de tortura que ejerce el estado español. Soledad señala que desde el año 2009 el estado ha seguido una política de “arrancamiento” y comparte las estrategias de las feministas uruguayas para acompañar a estas madres en su lucha a partir de su propia experiencia con los desaparecidos durante la dictadura en Uruguay.`}</p>
      <p>{`Con `}<strong parentName="p">{`Rebeca Gregson Tovar`}</strong>{` nos cuestionamos el modelo de maternidad que impone el sistema moderno colonial en el que vivimos. Recorriendo su historia como madre venezolana migrada, resignificamos la importancia de los procesos de lucha y escucha y de la reparación como formas de justicia colectiva.`}</p>
      <p><strong parentName="p">{`Silvia Aquiles`}</strong>{` comparte la investigación que ha ido realizando a lo largo de estos últimos años, como madre afectada por el falso-SAP y la quita de custodias, sobre el sistema judicial español y su complicidad ante esta forma de tortura. Con los datos recogidos durante esta exhaustiva investigación, Silvia denuncia la impunidad del poder judicial y la prevalencia del SAP (no reconocido por la OMS) y otros conceptos misóginos en las guías de actuación judicial.`}</p>
      <p>{`Por último, `}<strong parentName="p">{`Daniela Ortiz`}</strong>{` señala la judicialización de la maternidad y la complicidad del estado con los progenitores agresores. Daniela denuncia la impunidad frente al abuso sexual infantil y la responsabilidad del estado español que ejerce más violencia sobre las mujeres que los propios agresores.`}</p>
      <p>{`Todas las intervenciones en esta mesa evidencian el clasismo, racismo y misoginia que subyace la política de “arrancamientos” persistente en el estado español y apuntan a la importancia de la investigación internacional para poder garantizar un proceso de reparación a estas maternidades cuestionadas y también con les niñes víctimas de abuso infantil.  `}</p>
      <Box sx={{
        ".mdx-embed": {
          backgroundColor: `primary`,
          padding: 4,
          mt: 6
        }
      }} mdxType="Box">
        <YouTube youTubeId="JFxwuiWDmC4" mdxType="YouTube" />
        <Themed.p><small>Mesa redonda</small></Themed.p>
      </Box>
    </ContainerNarrow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      